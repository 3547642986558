.product-card-container {
  position: relative;
  width: 85%;
  height: 75%;
  background: linear-gradient(
    15deg,
    rgba(232, 232, 232, 0) 0%,
    rgba(249, 249, 249, 0.15) 100%
  );
  z-index: 100;
  margin-left: auto;
  border-radius: 0.5em;
  padding: 1em;
}

.image-container {
  position: absolute;
  top: -45px; /* Adjust the value as needed */
  right: 0px; /* Adjust the value as needed */
}
.saving-slot-image-container {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-40%, -50%);
  margin-top: 1.25em;
}

.saving-slot-image-container img {
  height: clamp(25px, 25vw, 500px);
  transform: rotate(10deg);
}
.ss-card {
  margin-top: clamp(0.1em, 1vw, 1em);
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Adjust the value as needed */
}
.image-container button {
  position: absolute;
  right: 10%;
  left: 10%;
  bottom: 0%;
}
.phone-shadow {
  position: absolute;
  background: hsla(0, 0%, 5%, 0.3);
  height: 100%;
  width: 20%;
  z-index: -1;
  border-radius: 50%;
  transform: rotate(10deg);
  left: 55%;
  filter: blur(12px);
}

@media (max-width: 710px) {
  .product-card-container {
    padding: 0em;
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
  }
  .image-container {
    top: -25px; /* Adjust the value as needed */
  }
  .saving-slot-image-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ss-card {
    width: 50%;
  }
}
@media (max-width: 610px) {
  .saving-slot-image-container {
    margin-top: 0;
    top: 60%;
  }
  .saving-slot-image-container img {
    height: clamp(25px, 45vw, 500px);
  }
}
