nav {
  width: 100%;
  padding: 0.75em;
  z-index: 9999;
  margin-bottom: 1em;
  color: white;
  position: relative;
  backdrop-filter: blur(10px); /* Will give the blur effect */
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.1) 0%,
    rgba(249, 249, 249, 0.15) 100%
  );
}
.nav-inner-container {
  width: min(1350px, 100%);
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.links {
  display: flex;
  transition: all 0.3s ease;
}

.links.open {
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
  z-index: 1000;
  backdrop-filter: blur(10px);
  padding-top: 1em;
  background: rgba(255, 255, 255, 0.25);
}
.header-link.open {
  margin-top: 0.5em;
  padding-bottom: 0.3em;
  background: none;
  backdrop-filter: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0;
}

.links > * {
  margin-left: 0.5em;
}

.hamburger {
  display: none;
  cursor: pointer;
}

/* Media Query for tablets and smaller devices */
@media (max-width: 930px) {
  .links {
    display: none;
  }

  .hamburger {
    display: block;
  }
  nav {
    backdrop-filter: none; /* Will give the blur effect */
    background: none;
  }
}
