.popup {
  position: absolute;
  width: clamp(100px, 45vw, 250px);
  top: -100%;
  left: 50%;
  transform: translate(-50%, -15%);
  background-color: ;
  padding: 0.125em 0.5em;
  border-radius: 8px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.4);
  z-index: 9999;
  font-size: 0.7em;
  color: #fff;
  line-height: 0.9em;
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.1) 0%,
    rgba(249, 249, 249, 0.15) 100%
  );
  backdrop-filter: blur(10px);
}
.popup a {
  color: #fff;
  text-decoration: underline;
}

.popup-content {
  text-align: center;
}
