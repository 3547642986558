.cta-button {
  padding: 0.75em;
  font-weight: bold;
  color: white;
  border-radius: 0.5em;
  border: none;
  background: radial-gradient(
    circle at 100%,
    hsl(357, 79%, 47%),
    hsl(330, 79%, 47%)
  );
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  max-height: 3.5em;
}
.cta-button:hover {
  background: radial-gradient(
    circle at 100%,
    hsl(357, 79%, 53%),
    hsl(330, 79%, 53%)
  );
}
.product-page {
  width: 100%;
  overflow: hidden;
}

.product-info {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-info::before,
.product-info::after {
  content: '';
  position: absolute;

  filter: blur(100px); /*Increased blur */
  z-index: -1;
}

.product-info::before {
  width: 75%;
  height: 200%;
  border-radius: 150px;
  top: -200px;
  left: -150px;
  background: hsl(320, 79%, 47%, 0.075);
}

.product-info::after {
  width: 75%;
  height: 200%;
  border-radius: 200px;
  bottom: -200px;
  right: -150px;
  background: hsl(208, 79%, 47%, 0.075);
}
.product-info h2 {
  font-weight: normal;
  color: hsla(0deg, 0%, 22.05%, 90%);
  width: 110%;
  backdrop-filter: blur(25px);
  background: linear-gradient(
    15deg,
    rgba(232, 232, 232, 0.45) 0%,
    rgba(249, 249, 249, 0.65) 100%
  );
  padding: 1em 1.75em;
  border-radius: 5em;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  font-size: 1.25em;
  margin: 1.5em 0;
  text-align: center;
}
@media (max-width: 900px) {
  .product-info {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .product-info h2 {
    width: 100%;
    font-size: 1.5em;
  }
}

@media (max-width: 670px) {
  .product-info h2 {
    font-size: 1.3em;
    border-radius: 1em;
    background: linear-gradient(
      15deg,
      rgba(239, 203, 226, 0.15) 0%,
      rgba(198, 189, 235, 0.55) 100%
    );
    border: 2px rgba(255, 255, 255, 0.5) solid;
  }
}

.product-info-row {
  width: 100%;
  display: flex;
}
.open-popup-right {
  margin-left: auto;
}

.collapsed-icon {
  position: absolute;
  background-color: hsl(0, 0%, 33%);
  background-image: url(lf-logo.png);
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.65);
  z-index: 999;
  left: 1em;
  cursor: pointer;
}

.circle-behind {
  position: absolute;
  top: 0; /* Set top position to 50% */
  left: 1em; /* Set left position to 50% */
  width: 54px; /* Adjust the size of the circle to cover the parent element */
  height: 54px;
  border-radius: 50%;
  background-color: rgb(111, 40, 218);
  z-index: 0;
  filter: blur(5px);
  visibility: hidden;
}
.collapsed-icon::before {
  content: '';
  position: absolute;
  right: 100%;
  bottom: 0%;
  transform: scaleX(-1) translate(-10%, 60%) rotate(60deg);
  background-image: url(arrow.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%; /* Adjust the width and height as needed */
  height: 100%;
  z-index: 1000; /* Make sure the arrow appears above the .learnfast-collapsed element */
}
@media (max-width: 710px) {
  .collapsed-icon {
    margin-top: 0.5em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
  }
  .circle-behind {
    margin-top: 0.5em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
  }
  .collapsed-icon::before {
    transform: scaleX(-1) translate(10%, -30%) rotate(0deg);
  }
  #step3 {
    margin-top: 1.5em;
  }
}

.simplify-submit-button {
  color: white;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.15) 3px 3px 4px;
  width: clamp(6em, 20%, 25%);
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(
    90deg,
    rgb(111, 40, 218) 0%,
    rgb(86, 21, 214) 100%
  );
  border-style: none;
  border-radius: 1.25em;
  padding: 0.5em;
  position: relative;
  animation: lighten 20s linear infinite;
  cursor: pointer;
}
.simplify-submit-button:hover {
  background: linear-gradient(
    90deg,
    hsl(264, 71%, 56%) 0%,
    hsl(260, 82%, 51%) 100%
  );
}
@media (max-width: 710px) {
  .simplify-submit-button {
    padding: 0.75em;
  }
}

@keyframes lighten {
  0% {
    background: linear-gradient(
      90deg,
      rgb(111, 40, 218) 0%,
      rgb(86, 21, 214) 100%
    );
  }
  2% {
    background: linear-gradient(
      90deg,
      rgb(111, 40, 218) 0%,
      rgb(86, 21, 214) 100%
    );
  }
  4% {
    background: linear-gradient(
      90deg,
      hsl(264, 71%, 56%) 0%,
      hsl(260, 82%, 51%) 100%
    );
  }
  12% {
    background: linear-gradient(
      90deg,
      rgb(111, 40, 218) 0%,
      rgb(86, 21, 214) 100%
    );
  }
  100% {
    background: linear-gradient(
      90deg,
      rgb(111, 40, 218) 0%,
      rgb(86, 21, 214) 100%
    );
  }
}

.steps {
  width: 100%;
  color: hsl(0deg, 0%, 22.05%, 80%);
  font-size: clamp(1.75em, 1.25vw, 4em);
  font-weight: lighter;
  position: relative;
  margin-bottom: 0.5em;
  z-index: 10;
}
@media (max-width: 478px) {
  .steps {
    font-size: 1.25em;
  }
}

.steps-row {
  font-size: 1.15em;
  display: flex;
  margin: 0.25em 0;
}
.steps-row div:nth-child(2) {
  margin-left: 0.5em;
}

.how-it-works-container {
  background: radial-gradient(
    circle at 0%,
    rgba(255, 255, 255, 0.45),
    rgba(255, 255, 255, 0.35)
  );
  padding: 1.35em;
  border-radius: 0.5em;
}

.how-it-works-underline {
  position: relative;
  display: inline-block;
  width: auto;
}
.how-it-works-underline::after {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 1px solid rgb(111, 40, 218);
  bottom: -1px; /* Adjust the value to control the distance below the text */
  left: -2px;
  right: 2px;
}
.how-it-works-underline-bold::after {
  bottom: -0.15em;
  border-bottom: 3px solid rgb(111, 40, 218);
}

.highlight {
  content: '';
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 105%;
  z-index: 0; /* Place the pseudo element right under the content */
  top: 0;
  left: -1%;
  background: radial-gradient(
    circle at 0%,
    hsl(357, 79%, 47%),
    hsl(330, 79%, 47%)
  );
  animation-duration: 9.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-name: highlight-animation;
}

@keyframes highlight-animation {
  0% {
    width: 0%;
    opacity: 0;
  }
  25% {
    width: 0%;
    opacity: 0;
  }
  50% {
    width: 102%;
    opacity: 0.7;
  }
  60% {
    width: 102%;
    opacity: 0.7;
  }
  85% {
    width: 102%;
    opacity: 0;
  }
  100% {
    width: 0%;
    opacity: 0;
  }
}

.how-it-works-popup-icon {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
}
.how-it-works-popup-container {
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
}

@media (max-width: 710px) {
  .how-it-works-popup-row {
    flex-direction: column;
  }
}

.mouse-icon {
  position: absolute;
  height: 1.5em;
  right: 0;
  animation: mouseCircle 20s linear infinite;
}

@keyframes mouseCircle {
  0% {
    transform: rotate(0deg) translateX(25px) rotate(0deg);
  }
  10% {
    transform: rotate(-360deg) translateX(25px) rotate(360deg);
  }
  100% {
    transform: rotate(-360deg) translateX(25px) rotate(360deg);
  }
}

.small-text {
  font-size: 0.45em;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.025em;
  font-weight: normal;
  margin-top: 0.25em;
}

.bottom-cta {
  display: flex;
  align-items: center;
}

@media (max-width: 710px) {
  .bottom-cta {
    flex-direction: column;
  }
}

.tech-used-container {
  margin: 1.5em auto;
  padding: 1em;
  outline: 1px rgba(255, 255, 255, 0.5) solid;
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
}

.tech-used-row-container {
  display: flex;
  flex-direction: column;
}
.tech-used-row-container > * {
  margin-bottom: 0.5em;
}
