.footer {
  padding: 15px 0;
  color: hsla(0deg, 0%, 22.05%, 95%);
  margin-top: 2em;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.footer-logo {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.footer-logo img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 20px;
  font-weight: bold;
}

.footer-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

.footer-nav li {
  display: inline-block;
  margin-right: 15px;
}

.footer-nav a {
  color: rgb(244, 244, 244);
  text-decoration: none;
}

.footer-nav a:hover {
  color: rgb(244, 244, 244);
}

.footer-social a {
  color: rgb(15, 247, 220);
  text-decoration: none;
  margin-right: 10px;
}

.footer-bottom {
  margin-top: 8px;
  text-align: center;
}

.footer-bottom p {
  margin: 5px 0;
}
