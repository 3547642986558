.saving-slots-info-container {
  width: 90%;
  margin: 2em auto;
}

.in-store-row {
  width: 100%;
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.05) 0%,
    rgba(249, 249, 249, 0.15) 100%
  );
  padding: 1.25em 2em;
  border-radius: 1em;
  display: flex;
  justify-content: space-between;
  color: hsl(0deg, 0%, 22.05%, 80%);
}

.saving-slots-image {
  max-width: min(300px, 85%);
  border-radius: 0.25em;
}
h2 {
  ccolor: hsl(0deg, 0%, 22.05%, 90%);
  font-weight: normal;
}
.in-store-row h2 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-left: 0em;

  font-weight: normal;
}
.in-store-row > div > .steps-row {
  margin-right: 1em;
}

@media (max-width: 747px) {
  .in-store-row {
    flex-direction: column;
    background: linear-gradient(
      0deg,
      rgba(232, 232, 232, 0.05) 0%,
      rgba(249, 249, 249, 0.15) 100%
    );
  }
  .in-store-row > div > .steps-row {
    margin: 0.75em 0em;
  }
}
@media (max-width: 360px) {
  .in-store-row {
    padding: 0.75em;
  }
}
