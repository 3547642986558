* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: hsl(240, 0%, 90%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:-webkit-any-link {
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.homepage {
  overflow: hidden;
}

.bio-container {
  width: 70%;
  margin: 30px auto;
  font-weight: 300;
  color: hsla(0deg, 0%, 22.05%, 0.8);
  position: relative;
}
.bio-container::before,
.bio-container::after {
  content: '';
  position: absolute;

  filter: blur(100px); /*Increased blur */
  z-index: -1;
}

.bio-container::before {
  width: 75%; /* Increased size */
  height: 200%; /* Increased size */
  border-radius: 150px; /* Adjusted to half the size for a perfect circle */
  top: -200px; /* Adjusted positioning */
  left: -150px; /* Adjusted positioning */
  background: hsl(320, 79%, 47%, 0.075);
}

.bio-container::after {
  width: 75%; /* Increased size */
  height: 200%; /* Increased size */
  border-radius: 200px; /* Adjusted to half the size for a perfect circle */
  bottom: -200px; /* Adjusted positioning */
  right: -150px; /* Adjusted positioning */
  background: hsl(208, 79%, 47%, 0.075);
}
.bio-container > h3 {
  font-size: 1.75em;
  font-weight: 300;
  color: hsla(0deg, 0%, 22.05%, 0.9);
}
.bio-container > h4 {
  font-size: 1.25em;
  font-weight: 300;
  color: hsla(0deg, 0%, 22.05%, 0.8);
}
.main-content {
  text-align: center;
  padding: 0rem 2rem;
}

.card-container {
  flex: 1;
  padding: 0.5em 1.5em;
  position: relative;
}
.card {
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.3) 0%,
    rgba(249, 249, 249, 0.35) 100%
  );
  outline: 1px hsla(0, 0%, 100%, 0.5) solid;
  padding: 1.5em 1em;
  color: hsl(0deg, 0%, 22.05%);
  border-radius: 0.35em;
  cursor: pointer;
  backdrop-filter: blur(10px);
  height: 100%;
  transition: background 0.3s ease, color 0.3s ease;
}
.card-name {
  padding: 0.5em;
  border-bottom: 0.5px white solid;
  text-align: middle;
}

.card-container:hover .card {
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.25) 0%,
    rgba(249, 249, 249, 0.35) 100%
  );
  color: rgba(255, 255, 255, 1);
}

.card-glow {
  position: absolute;
  background: linear-gradient(
    -75deg,
    hsla(357, 79%, 47%, 0.05),
    hsla(342, 79%, 47%, 0.05)
  );
  inset: -0.125em 0.5em;
  z-index: -1;
  border-radius: 1em;
  filter: blur(16px);
  transition: background 0.3s ease;
}
.card-container:hover .card-glow {
  background: linear-gradient(
    -75deg,
    hsla(357, 79%, 47%, 0.65),
    hsla(342, 79%, 47%, 0.95)
  );
}

.card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.25em;
  background: linear-gradient(
    75deg,
    rgba(239, 239, 239, 0.45) 0%,
    rgba(249, 249, 249, 0.55) 100%
  );
  padding: 0.75em 1.5em;
  border-radius: 2em;
  transition: background 0.3s ease;
}

.card-container:hover .card-header {
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.35) 0%,
    rgba(249, 249, 249, 0.45) 100%
  );
}

.card-row {
  display: flex;
}

@media (max-width: 1000px) {
  .bio-container {
    width: 85%;
  }
  .bio-container > h3 {
    font-size: max(0.85em, 2.5vw);
  }
  .bio-container > h4 {
    font-size: max(0.65em, 2vw);
  }
  .card-row {
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;
  }

  .card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      'title content'
      'header content';
    padding: 1em;
  }

  .card > span {
    grid-area: title;
  }

  .card > div:last-of-type {
    grid-area: content;
  }
  .card-name {
    text-align: left;
  }

  .card-header {
    grid-area: header;
    width: auto;
  }
  .card > div:last-of-type {
    height: 100%;
  }
  .card > div:last-of-type > p {
    padding-left: 1em;
    text-align: left;
    margin-block-end: 0;
    margin-block-start: 0;
  }
}
@media (max-width: 1000px) {
  .main-content {
    padding: 0.25em;
  }
}

@media (max-width: 647px) {
  .card {
    grid-template-columns: 1fr 1fr;
  }
  .bio-container {
    width: 95%;
  }
}

@media (max-width: 567px) {
  .card {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'header'
      'content';
  }
  .card-header {
    margin: 1em 0;
  }
  .card > div:last-of-type > p {
    padding-left: 0em;
  }
}
