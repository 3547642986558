.shape {
  position: absolute;
  border-radius: 50%;
  animation: 8s moveShape linear infinite;
  opacity: 0; /* Initially hidden */
  transition: opacity 4s ease-in-out; /* Add transition */
}
.shape.static {
  animation: none;
}
.shape.show {
  opacity: 0.5; /* Show the shape */
}

@keyframes moveShape {
  0% {
    transform: rotate(0deg) translate(0px, 0px);
  }
  100% {
    transform: rotate(-100deg) translate(1200px, 400px);
  }
}

.hero-image {
  position: relative;

  width: 100%;
  display: flex; /* Add flexbox */
  flex-direction: column;
  align-items: flex-end; /* Align items at the bottom */
  min-height: 400px;
}

.gradient {
  background: linear-gradient(hsl(357, 79%, 47%), hsl(342, 79%, 47%));
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  clip-path: polygon(0 90%, 100% 100%, 100% 0, 0 0);
  -webkit-clip-path: polygon(0 90%, 100% 55%, 100% 0, 0 0);
  overflow: hidden;
}

.static {
  clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
  opacity: 85%;
}

.gradient > * {
  filter: blur(35px);
}

.page-title {
  white-space: pre-line;
  margin-top: auto;
  margin-bottom: 0%;
}

.page-title {
  position: absolute;
  bottom: 0;
  z-index: 999;
  opacity: 40%;
  line-height: 0.9em;
  mix-blend-mode: color-burn;
  color: hsl(210, 88%, 55%);
  font-weight: bold; /* Increase font weight */
  font-size: 10vw;
}
@media (max-width: 710px) {
  .page-title {
    font-size: 14vw;
  }
}

.title2 {
  color: hsl(0deg, 0%, 22.05%);
  opacity: 100%;
  z-index: 1;
  mix-blend-mode: normal;
}
.title-box {
  display: flex;
  width: 50%;
  margin-top: auto;
  margin-bottom: 1.5em;
}
.title-container {
  width: 75%;
}
.hero-content-split {
  margin-top: auto;
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.link {
  color: hsla(0, 0%, 100%, 0.75);
  z-index: 999;
  text-decoration: none;
}
.header-link {
  padding: 0.5em 1.5em;
  border-radius: 1.25em;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.025);
}
.header-link-home {
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.15) 0%,
    rgba(249, 249, 249, 0.2) 100%
  );
}
.header-link:hover {
  color: hsla(0, 0%, 100%, 1);
  background: linear-gradient(
    75deg,
    rgba(232, 232, 232, 0.2) 0%,
    rgba(249, 249, 249, 0.25) 100%
  );
  outline: rgba(255, 255, 255, 0.75) solid 1.5px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.15);
}

.hero-rside-box {
  display: flex;
  width: 50%;
  height: 100%;
}

@media (max-width: 710px) {
  .hero-content-split {
    margin-top: 1em;
  }
  .title-box {
    width: 100%;
  }
  .hero-rside-box {
    width: 100%;
    height: auto;
    margin-top: 1em;
  }
  .rside-ss {
    width: 50%;
  }
}
