.white-line {
  height: 0.05em;
  width: 15%;
  margin: 1em;
  background: white;
  opacity: 0.5;
  margin-left: auto;
  margin-right: auto;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2em;
  width: 120%;
  transform: translate(-10%, 0%);
  margin-top: 1em;
}

.example-item {
  background: linear-gradient(
    hsla(357, 79%, 47%, 0.65),
    hsla(342, 79%, 47%, 0.65)
  );
  position: relative;
  z-index: 9;
  border-radius: 0.5em;
  overflow: hidden;
  padding: 1em;
  color: rgb(244, 244, 244);
  font-size: 1.1em;
  line-height: 1.25em;
}

.example-item h3 {
  position: relative;
  z-index: 999;
  font-weight: lighter;
  text-align: center;
  margin: 0;
  font-size: 1.5em;
  letter-spacing: 0.025em;
  margin-bottom: 0.35em;
  margin-top: 0.25em;
}

.example-item h3.h3-left {
  letter-spacing: 0.0125em;
  font-size: 1.65em;
  text-align: left;
  line-height: 1em;
}

.example-row {
  display: flex;
}

.example-row * {
  width: 50%;
  padding: 2px;
}

@media (max-width: 1333px) {
  .example-row {
    flex-direction: column;
  }
  .example-row * {
    width: 100%;
    padding: 2px;
  }
}
